import Card from 'antd/es/card/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getFormLink } from '../helpers';

const CustomToolRequestCard: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <Link to={getFormLink(i18n.language)} target="_blanc">
      <Card
        style={{ height: '100%', backgroundColor: 'rgb(208 234 255)' }}
        hoverable
      >
        <div style={{ maxWidth: '460px', whiteSpace: 'pre-line' }}>
          <h2>
            <span>{t('main.customToolRequest')}</span>
            <span style={{ color: '#1073ff', textDecoration: 'underline' }}>
              {t('main.customToolRequest2')}
            </span>
            <span>{t('main.customToolRequest3')}</span>
          </h2>
        </div>
      </Card>
    </Link>
  );
};

export { CustomToolRequestCard };
